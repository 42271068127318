import { getFullPagePath, getPagePath } from '../../utils/appRoutes';
import { ARD_PERMA_SEARCH, ARD_SEARCH } from '../../utils/appRoutes.definitions';

export const createPaginatedCanonicalUrl = (brandsFilter, categoryPath) => (page) => {
  const searchParams = new URLSearchParams();
  if (page && page > 1) searchParams.set('page', page);
  if (brandsFilter.length === 1) searchParams.set('brand', brandsFilter[0]);

  const pageCanonical = new URL(getFullPagePath(ARD_SEARCH, categoryPath));
  pageCanonical.search = searchParams.toString();
  return pageCanonical.toString();
};

export const constructSRPCanonical = (permaSearchCanonical, categoryPath, page, brandsFilter) => {
  const isCategorySearchPage = !!categoryPath;
  if (permaSearchCanonical?.length) {
    return getFullPagePath(ARD_PERMA_SEARCH, permaSearchCanonical[0], permaSearchCanonical[1]);
  }
  if (isCategorySearchPage) return createPaginatedCanonicalUrl(brandsFilter, categoryPath)(page);
  return getPagePath(ARD_SEARCH);
};
